.single-blog-page {
}

.single-blog-page-sub-section {
  gap: 5rem;
}

.single-blog-page-banner-section {
  flex-direction: column;
  align-self: flex-start;

  text-align: left;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  border-radius: 15px;

  color: var(--primary-white);
}

.single-blog-page-banner-text-section {
  width: 90%;
  height: 90%;

  flex-direction: column;
  text-align: left;

  align-items: flex-start;
  justify-content: flex-end;

  gap: 5px;
}

.single-blog-page-banner-text-section > div {
  gap: 5rem;
}

.single-blog-page-content-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 2rem;

  text-align: left;
}

.single-blog-page-content-container > h2 {
  font-size: 46px;
}

.single-blog-page-para-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 1rem;

  text-align: left;
}

.single-blog-page-para-container > h4 {
  font-size: 32px;
}

@media screen and (max-width: 768px) {
  .single-blog-page-banner-section {
    margin-top: 50px !important;

    width: 100% !important;
    height: 40vh !important;
  }

  .single-blog-page-banner-text-section > h1 {
    text-align: center !important;
  }
  .single-blog-page-sub-section {
    width: 95% !important;
  }

  .single-blog-page-content-container,
  .single-blog-page-para-container {
    width: 100% !important;
    align-items: flex-start !important;
  }

  .single-blog-page-content-container > h2 {
    font-size: 2rem;
  }

  .single-blog-page-para-container > h4 {
    font-size: 1.5rem !important;
  }
}
