@media screen and (max-width: 768px) {
  .privacy-page-paras {
    width: 90%;
  }
  .sub-general-term-section {
    width: 90% !important;
  }
}

.privacyPage-section {
}
.sub-privacy-page {
}

.privacy-page-paras {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 20px;
  margin-bottom: 10px;
  line-height: 1.8;
}
.sub-general-term-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.sub-general-term-heading {
  display: flex;
  align-self: center;
}

.sub-disclaimer-section,
.sub-general-term-section,
.sub-privacy-page,
.sub-refundPage-section,
.sub-work-with-us-section {
  margin: 30px 0px;
  color: #083a44;
}
/* .sub-disclaimer-section > .privacy-page-paras > p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
} */
.privacy-page-paras > h3 {
  text-align: left;
}

.privacy-page-paras > p {
  color: #454545;
}

.refund-policy-table {
  width: 90%;
  border: 1px solid gray;
}
.refund-policy-table > tr {
  border-bottom: 1px solid black;
}
.refund-policy-table > tr > td {
  border-right: 1px solid black;
  border-bottom: 1px solid black;

  padding: 10px;
  text-align: left;
}
.refund-policy-table > tr > th {
  border-right: 1px solid black;
  padding: 10px;
  border-bottom: 1px solid black;
}
