@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .experience-cards-container {
    height: 10vh !important;
  }
}

@media screen and (max-width: 768px) {
  .experience-cards-container {
    width: 100% !important;
  }

  .experience-cards:hover .experience-cards-extra-container {
    display: none !important;
  }
}

.experience-cards {
  position: relative;
  flex-direction: column;

  position: relative;

  cursor: pointer;
}

.experience-cards-container {
  text-align: left;

  width: 15vw;
  height: 15vh;

  padding-left: 10%;

  background-color: transparent;

  border-left: 2px solid #fff;

  color: #fff;

  transition: all 0.5s ease-in-out;
}

.exp-card-highlighted {
  /* background-color: var(--primary-white);
  color: black; */

  transition: all 0.5s ease-in-out;
}

/* .experience-cards-line {
  position: absolute;

  left: 50%;

  z-index: 1;

  background-color: #fff;
  width: 2px;
} */
/* .experience-cards-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 2;
} */

.experience-cards-line {
  position: absolute;
  z-index: 1;

  height: 2px;
  bottom: 0;
  width: 2px;
  transition: all 0.5s ease-in-out;
}

.line-on {
  bottom: -40%;
  left: -5%;

  background-color: #fff;

  height: 100px;
  width: 2px;
}

.line-on::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 20px; /* Adjust the size of the circle as needed */
  height: 20px; /* Adjust the size of the circle as needed */
  border-radius: 50%;
  z-index: 2; /* Ensure the circle is on top of the line */
}
