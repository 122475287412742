.corporatePartners-page {
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    width: 100%;
    align-items: center;
    gap: 4rem;
}
.corporatePartners-page-hero {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 90%;
    padding: 1rem;
}
.corporatePartners-page-hero-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;
}
.corporatePartners-page-hero-left-head {
    font-size: 50px;
    width: 50%;
    color: #3D3D3D;
    text-align: start;
}
.corporatePartners-page-hero-right {
    width: 50%;
}
.corporatePartners-page-hero-right > img {
    width: 100%;
}
.corporatePartners-page-faculty {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
}
.corporatePartners-page-faculty-left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 50%;
    text-align: start;
}
.corporatePartners-page-faculty-left > p {
    line-height: 28px;
}
.corporatePartners-page-faculty-right {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: start;
}

.corporatePartners-page-magnificent {
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}
.corporatePartners-page-magnificent-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.corporatePartners-page-magnificent-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: start;
}
.corporatePartners-page-magnificent-right > img {
    width: 50%;
}
.corporatePartners-page-magnificent-left-imageup {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.corporatePartners-page-magnificent-left-imageup > img {
    width: 50%
}
.corporatePartners-page-magnificent-left-imagedown {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.corporatePartners-page-magnificent-left-imagedown > img {
    width: 50%;
}
.corporatePartners-page-placement {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    
}
.corporatePartners-page-placement > p {
    text-align: start;
}

@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .corporatePartners-page-hero {
        flex-direction: column;
    }
    .corporatePartners-page-hero-left {
        width: 100%;
    }
    .corporatePartners-page-hero-right {
        width: 100%;
    }
    .corporatePartners-page-hero {
        gap: 1rem;
    }
    .corporatePartners-page-faculty {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }
    .corporatePartners-page-faculty-left {
        width: 100%;
    }
    .corporatePartners-page-faculty-right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .corporatePartners-page-magnificent {
        flex-direction: column-reverse;
    }
    .corporatePartners-page-magnificent-left {
        width: 100%;
    }
    .corporatePartners-page-magnificent-right {
        width: 100%;
        align-items: center;
    }
    .corporatePartners-page-magnificent-left-imageup {
        width: 100%;
    }
    .corporatePartners-page-magnificent-left-imagedown {
        width: 100%;
    }
    
}

@media screen and (max-width: 650px) {
    .corporatePartners-page-faculty-right {
      grid-template-columns: repeat(1, 1fr);
    }
}