/* ProgramBBA.css */

.program-bba-container {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  gap: 100px;
}

.banner-section {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 50px;
  min-height: 80vh;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.banner-content {
  color: var(--primary-white);

  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 20px;

  text-align: left;

  padding-left: 30px;
}

.banner-content h2 {
  font-size: 4rem;
  font-weight: 600;
}

.banner-content span {
  font-size: 1.25rem;
}

.btn-group {
  display: flex;

  justify-content: flex-start;
  align-items: center;

  gap: 20px;
}

.scholarship-box {
  padding: 1rem 2rem;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 1rem;

  color: var(--primary-white);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  /* width: 300px; */
}

.scholarship-box:hover {
  transform: scale(1.1);
  font-weight: bold;

  transition: all 0.3s ease-in-out;
}

.contact-form-section {
  background-color: var(--primary-white);

  border-radius: 15px;

  padding: 20px;

  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  text-align: left;
}

.contact-form-section > h3 {
  font-size: 2rem;

  text-align: left;

  display: flex;

  align-self: flex-start;
}

.contact-form-wrapper {
  height: 20rem;
}

.additional-section {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 2px solid #cbd5e0;
  padding-bottom: 10px;
}

.additional-section h3 {
  font-size: 2.25rem;
  font-weight: 600;

  display: flex;
  align-self: center;
}

.additional-section p {
  font-size: 1rem;
}

.degree-classification-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  text-align: left;
}

.degree-classification-section > h5 {
  font-size: 1.5rem;

  font-weight: normal;
}

.degree-grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.degree-major,
.degree-minor {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.degree-major h4,
.degree-minor h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.degree-major ul,
.degree-minor ul {
  width: 100%;

  display: grid;

  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 30px;
}

.degree-grid > img {
  width: 25%;
}

.details-cards-section {
  background-color: var(--primary-color-1);

  padding: 2rem 1rem;

  width: 90%;

  border-radius: 1rem;

  gap: 1rem;

  flex-wrap: wrap;
}

.different-edge-section {
  width: 90%;

  flex-direction: column;

  gap: 2rem;
}

.different-edge-section > .different-edge-text {
  flex-direction: column;

  gap: 1rem;
}

.different-edge-section > .different-edge-text > h3 {
  font-size: 2rem;
}

.different-edge-cards-container {
  flex-wrap: wrap;

  gap: 2rem;

  padding: 3rem 0.5rem;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.bba-advanvcement-section {
  flex-direction: column;

  width: 90%;

  gap: 2rem;

  padding: 1rem 2rem;

  background-color: #2fceff2b;

  border-radius: 8px;
}

.bba-advancement-heading {
  flex-direction: column;

  gap: 1rem;
}

.bba-advancement-cards-section {
  flex-wrap: wrap;

  justify-content: center;
  align-items: flex-start;

  gap: 10rem;
  row-gap: 3rem;
}

.bba-advancement-cards {
  width: 400px;

  justify-content: center;

  align-items: flex-start;

  gap: 1rem;
}

.bba-advancement-cards > img {
  width: 50px;

  padding: 1rem;

  background-color: var(--primary-white);
  border-radius: 8px;
}

.bba-advancement-cards > div {
  flex-direction: column;

  align-items: flex-start;

  text-align: left;

  gap: 0.5rem;
}

.bba-advancement-cards > div > h5 {
  font-size: 1.2rem;
}

.bba-key-skills-sections {
  flex-direction: column;
  gap: 5rem;
}

.bba-key-skills-sections > ul {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5rem;
  row-gap: 3rem;
}

.bba-key-skills-sections > ul > li {
  font-size: 18px;
  font-weight: bold;
}

@media only screen and (max-width: 1080px) {
  .banner-section {
    flex-direction: column !important;

    justify-content: center !important;
    align-items: center !important;

    text-align: center !important;
    height: auto !important;

    padding: 20px 0px !important;

    width: 100% !important;
  }

  .banner-content {
    width: 95% !important;
    padding: 0 !important;
    justify-content: center !important;
    align-items: center !important;

    text-align: center !important;
  }

  .contact-form-section {
    width: 90% !important;

    justify-content: center !important;
    align-items: center !important;

    text-align: center !important;
  }

  .contact-form-wrapper {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-form-wrapper {
    height: 27rem !important;
  }

  .degree-major ul,
  .degree-minor ul {
    width: 100%;

    display: grid;

    grid-template-columns: repeat(2, 1fr) !important;
    column-gap: 20px;
    row-gap: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .additional-section {
    text-align: left;
  }
  .banner-content h2 {
    font-size: 2rem !important;
  }

  .btn-group {
    flex-direction: column !important;

    justify-content: center !important;

    align-items: center !important;
  }
}

@media (max-width: 768px) {
  .degree-major,
  .degree-minor {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .degree-major ul,
  .degree-minor ul {
    width: 100%;

    display: grid;

    grid-template-columns: repeat(2, 1fr) !important;
    column-gap: 20px;
    row-gap: 30px;
  }

  .degree-grid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    flex-direction: column !important;
  }

  .degree-grid > img {
    width: 100% !important;
  }

  .bba-key-skills-sections > ul {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    column-gap: 10rem;
    row-gap: 5rem;
  }
}
@media (max-width: 640px) {
  .degree-major ul,
  .degree-minor ul {
    width: 100%;

    display: grid;

    grid-template-columns: repeat(1, 1fr) !important;
    column-gap: 20px;
    row-gap: 30px;
  }

  .bba-key-skills-sections > ul {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    column-gap: 10rem;
    row-gap: 5rem;
  }
}

/* @media (min-width: 640px) {
  .banner-section {
    width: 90%;
    flex-direction: row;
    align-items: center;
    min-height: initial;
  }

  .contact-form-section {
    width: 50%;
  }

  .additional-section {
    width: 90%;
  }

  .degree-grid {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .banner-content {
    width: auto;
  }

  .contact-form-section {
    width: 40%;
  }
}

@media (min-width: 1024px) {
  .degree-grid {
    width: 90%;
  }
} */
