.program-page-banner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin: 40px 0px; */
  padding-top: 40px;
  position: relative;
}
.program-page-banner-img {
  width: 100%;
}
.program-page-banner-heading {
  position: absolute;
  color: white;
  /* display: flex;
  align-self: center;
  justify-self: center; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.program-page-banner-img > img {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.program-page-banner-button,
a {
  margin: 20px 0px;
  color: #4f4f4f;
}

@media screen and (max-width: 2000px) {
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 650px) {
  .program-page-banner-container {
    width: 100% !important;
    height: 50vh !important;
  }
  .program-page-banner-img {
    width: 100%;
    height: 100%;

    border-radius: 8px;
  }
  .program-page-banner-img > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;

    border-radius: 8px;
  }
  .program-page-banner-heading {
    font-size: 18px;
  }
}
