.application-form-main-section {
  width: 100%;
  margin: 75px 0px;
}
.application-form-box {
  background-color: #08bddc0d;
}

.student-details {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 40px 0px;
  padding: 40px 0px;
}

.application-form-field {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 5%;
  gap: 20px;
}

.application-form-field-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* width: 20%; */

  gap: 20px;
}
.application-form-field-input_flex_25 {
  flex: 0.25;
}
.application-form-field-input_flex_3 {
  flex: 0.33;
}
.application-form-field-input_flex_5 {
  flex: 0.5;
}
.application-form-field-input_flex_1 {
  flex: 1;
}
.application-form-field-input > label {
  font-size: 16px;
  font-weight: 800;
  color: #767676;
}

.application-form-field-input > input,
select {
  width: 100%;
  height: 30px;
  border: 1px solid black;
  border-radius: 20px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  font-weight: 600;
  padding: 10px 0;
  padding-left: 10px;
}

.application-form-field-input > select {
  height: 51px;
}
.application-form-field-input > select > option {
  padding: 10px;
}

.application-form-cast-field {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.application-form-cast-field-box {
  background-color: white;
  width: 10%;
  display: flex;
  gap: 10px;
  padding: 20px 10px;
  border-radius: 10px;
}

.academics-details {
  /* background-color: #08bddc0d; */
  margin: 20px 0px;
  width: 100%;
}
.graduation-details {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 40px 0px;
  padding: 40px 0px;
}
.fields {
  width: 100%;
}
.upload_file_button {
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 40px;
  border: 1px solid #269eff;
  background: #fff;
  padding: 10px 12px;
}

.btn {
  /* border: 2px solid gray; */
  color: #269eff;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.form_button {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.form_button > button {
  width: 100px;
  background-color: transparent;
  outline: transparent;
  border: transparent;
  font-size: 20px;
  color: #269eff;
}
.loginFormError {
  font-size: 12px;
  color: red;
  margin: 0 !important;
}
.Signature_preview {
  width: 150px;
  height: 30px;
}
@media only screen and (max-width: 750px) {
  .application-form-field {
    flex-direction: column;
  }
  .application-form-field-input{
    width: 100%;
  }
  .application-form-field1{
    flex-direction: row !important;
  }
  .application-form-cast-field-box{
    width: 100px !important;
  }
}
