.bba-details-card {
  background-color: var(--primary-white);

  justify-content: flex-start;

  flex-direction: column;

  gap: 1rem;

  padding: 1rem;

  border-radius: 1rem;

  width: 150px;
  height: 180px;
}

.bba-details-card > div {
  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  gap: 1rem;

  /* color: var(--primary-white); */
}
