.fees-payment-page {
  margin-top: 100px;
}

.fees-payment-page
  > .fees-payment-main-section
  > .fees-payment-breadcrumbs-section {
  display: flex;
  align-self: flex-start;
}

.fees-payment-page > .fees-payment-main-section > .fees-payment-mid-section {
  margin-top: 2rem;

  min-height: 40vh;
  height: 40vh;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;

  gap: 100px;
}

.fees-payment-button-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fees-payment-border {
  width: 2px;
  height: 100%;

  background-color: var(--primary-text-heading);
}

@media screen and (max-width: 1024px) {
  .fees-payment-page {
    width: 95% !important;
  }
  .fees-payment-page > .fees-payment-main-section > .fees-payment-mid-section {
    justify-content: center !important;
  }

  .fees-payment-button-section {
    justify-content: center !important;
    align-items: center !important;
  }

  .fees-payment-border {
    display: none !important;
  }
}
