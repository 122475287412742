.edge-cards {
  display: flex;

  justify-content: flex-start;
  align-items: center;

  gap: 10px;

  width: 280px;
  height: 30px;
  padding: 10px 5px;

  background-color: var(--primary-white);

  border-radius: 8px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
