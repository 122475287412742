.download-brochure-comp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-brochure-comp > div > .brochure-button-style-button {
  padding: 5px 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1rem;

  border-radius: 50px;

  font-weight: bold;
  color: var(--primary-color-1);

  background-color: var(--primary-white);

  border: 1px solid var(--primary-color-1);
}

.download-brochure-comp > div > .brochure-list-style-button {
  border: none;
  background-color: var(--primary-white);
  padding: 0;

  font-size: inherit;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1rem;
}

.ProgramPGDMDigitalMarketing-ModalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ProgramPGDMDigitalMarketing-ModalForm-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
.ProgramPGDMDigitalMarketing-ModalForm-form > input {
  padding: 1rem;
  width: 80%;
  border-bottom: 1px solid #8e8e8e;
}
.ProgramPGDMDigitalMarketing-ModalForm-button {
  background: #07a7b3;

  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
