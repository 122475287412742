@media screen and (max-width: 768px) {
  .about-heading {
    width: 90% !important;
    font-size: 10px;
  }
  .about-about-us {
    width: 90% !important;
  }
  .about-about-us-img-sub-box {
    flex-direction: column;
  }
  .about-directurs-message {
    width: 90% !important;
  }
  .about-our-mentors {
    width: 90% !important;
  }

  .about-our-mentors-card-box {
    width: 90% !important;
    flex-direction: column !important;
  }
  .about-our-mentors-card {
    width: 90% !important;
    gap: 3rem !important;
    align-items: center !important;
  }

  .about-compus-tour-box {
    overflow-x: scroll;
    gap: 25px;
  }
  .about-compus-tour-box > .bigImg {
    width: 70% !important;
  }
  .about-compus-tour-box > img {
    width: 100% !important;
  }
  .about-compus-tour {
    width: 90% !important;

    gap: 5rem;
  }
}

.about-container img {
  /* flex-direction: column; */
  width: 100%;
  border-radius: 5px;
}

.about-text {
  padding: 15px;
}

.about-text > h3 > a {
  text-decoration: none;
  color: black;
}

.about-heading {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin: 15px;
  padding: 15px;
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 12px;
  height: 36px;
}
.about-heading-1 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
  border-radius: 25px;
}

.fade-in {
  -webkit-animation: fade-in 2.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 2.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin: 20px;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-29 15:24:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.about-menu-active {
  font-weight: bold;
  border: 1px solid rgb(161, 161, 161);
  padding: 8px;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
}
.about-about-us {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.about-about-us > h3 {
  display: flex;
  align-self: flex-start;
}
.about-about-us > h4 {
  display: flex;
  justify-self: center;
}
.about-about-us-para {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--primary-text-para);
}

.about-about-us-img-main-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}
.about-about-us-img-sub-box {
  width: 90%;
  display: flex;

  gap: 68px;
}
.about-about-us-img-sub-box > img {
  width: 50%;
  object-fit: cover;
  border-radius: 5px;
}

.about-directurs-message {
  width: 60%;
}
.about-directurs-message-card-box-img {
  /* box-shadow: 0px 5px 10px var(--secondary-highlight-color-2); */
  filter: drop-shadow(0px 4px 4px #267fef);
  margin-bottom: 10px;
}

.about-directurs-message-card-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.about-directurs-message-card-box-img-name > h3 {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.about-directurs-message > h3 {
  text-align: left;
  padding: 10px 0;
}
.about-directurs-message-para {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--primary-text-para);
}

.about-directurs-message > h5 {
  text-align: right;
  padding: 15px 0;
}

/* .about-our-mentors {
  width: 60%;
} */
.about-our-mentors {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.about-our-mentors > p {
  text-align: justify;
  color: var(--primary-text-para);
  padding: 10px 0;
}

.about-our-mentors-card-box {
  background-color: rgba(240, 249, 255, 0.55);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 22px;
  flex-wrap: wrap;
}

.about-our-mentors-card {
  padding: 10px;
  border: 1px dashed rgba(185, 185, 185, 1);
  width: 28%;
  /* height: 500px; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-our-mentors-card-img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}

.about-our-mentors-card-img > img {
  width: 90%;
}
.about-food {
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  /* position: absolute;
  top: 2%;
  right: 10%; */
}

.about-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 80%;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  /* font-size: 10px; */
  padding: 7px;
  /* position: absolute;
  bottom: -7%; */
  box-shadow: 0px 2px 10px rgb(212, 212, 212);
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-data > h4 {
}
.about-data > h5 {
  color: #0867ed;
}
.about-data > h6 {
  color: #b0b0b0;
}
.about-our-mentors-card-icon {
  display: flex;
  gap: 7px;
  justify-content: center;
}

.about-compus-tour {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.reverse {
  flex-direction: row-reverse !important;
}

.about-compus-tour-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.about-compus-tour-box > .bigImg {
  object-fit: cover;
  object-fit: cover;
  border-radius: 7px;
  width: 50%;
}

.about-compus-tour-box > img {
  object-fit: cover;
  border-radius: 7px;
  width: 20%;
}
