@media screen and (max-width: 768px) {
  .bannerPage-container {
    margin-top: 50px !important;
  }
}

.bannerPage-container {
  display: flex;
  flex-direction: column;
}
.bannerPage-container img {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
}
