@media screen and (min-width: 769px) and (max-width: 1024px) {
  .home-swiper-container > h2 {
    font-size: 1rem !important;

    width: 100% !important;

    text-align: left;

    display: flex !important;

    margin-bottom: 0 !important;
  }

  /* .home-swiper-container {
    background-size: cover;
    background-position: center;

    width: 100%;
    height: 100%;

    justify-content: flex-start;
    align-items: center;

    padding-left: 10px !important;

    color: #fff;
  } */

  .home-banner {
    height: auto !important;

    padding-top: 0 !important;

    flex-direction: column !important;

    margin-top: 50px !important;
  }

  .home-swiper-container {
    height: 30vh !important;
    width: 100% !important;
    align-items: flex-end !important;
    justify-content: flex-start !important;
  }
  .home-marquee-section {
    margin-top: 50px;
  }
  .home-banner-form-section {
    position: relative !important;
    width: 90% !important;
    height: 50vh !important;
  }
}

@media screen and (max-height: 700px) {
  .home-banner {
    height: 100vh !important;
  }
}

@media only screen and (max-width: 768px) {
  .home-swiper-container > h2 {
    font-size: 1rem !important;

    width: 100% !important;

    text-align: left;

    margin-bottom: 0 !important;
  }

  /* .home-swiper-container {
    background-size: cover;
    background-position: center;

    width: 100%;
    height: 100%;

    justify-content: flex-start;
    align-items: center;

    padding-left: 10px !important;

    color: #fff;
  } */

  .home-banner {
    height: auto !important;

    margin-top: 50px !important;
  }

  .home-swiper-container {
    height: 30vh !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }
  .home-marquee-section {
    margin-top: 50px;
  }
  .home-banner-form-section {
    position: relative !important;
    width: 90% !important;
    height: auto !important;
  }
}

.home-banner {
  padding-top: 2rem;

  width: 100%;
  height: 60vh;

  position: relative;
  object-fit: contain;
}

.home-banner > .banner-box-top {
  width: 90%;
  position: absolute;
  bottom: -1px;
  left: -1px;

  z-index: 1;
}

.mySwiper {
  width: 100% !important;
  height: 100% !important;
  border-radius: 40px;
}

.home-banner > .mySwiper > img {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}

.home-swiper-container {
  background-size: 120% 160%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 2s ease-in-out;

  width: 100%;
  height: 100%;

  justify-content: flex-start;
  align-items: center;

  padding-left: 20px;

  color: #fff;
}

.home-swiper-container.zoom-out {
  background-size: 100% 140%;

  overflow: hidden;
}

.home-swiper-container > h2 {
  font-size: 1.8rem;

  width: 50%;

  background-color: var(--primary-buttons-color);

  padding: 20px;

  border-radius: 8px;

  text-align: left;
}

.home-swiper-container > button {
  background-color: var(--primary-buttons-color);

  color: #fff;

  font-weight: bold;
  font-size: 1rem;
}

.home-banner-form-section {
  position: absolute;

  top: 20%;
  right: 3%;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 5%;

  width: 25%;
  height: 65%;

  background-color: #fff;

  padding: 1%;

  border-radius: 25px;
}

.home-banner-form-section > h2 {
  color: var(--primary-text-heading);
}

.home-banner-form {
  height: 80%;
  width: 90%;
}
