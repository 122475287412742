.Admin-section-page {
    padding: 1rem;
    width: 100%;
}

.Admin-section-page-header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 16px;
    background: var(--White, #FFF);
    box-shadow: 0px 2.317px 5.608px 0px rgba(0, 0, 0, 0.01), 0px 5.388px 13.038px 0px rgba(0, 0, 0, 0.01), 0px 9.675px 23.414px 0px rgba(0, 0, 0, 0.02), 0px 16.057px 38.858px 0px rgba(0, 0, 0, 0.02), 0px 26.453px 64.017px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Admin-section-page-header-search {
    border-radius: 4px;
    border: 1px solid #6E798C;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
}

.Admin-section-page-header-input {
    padding: 10px;
    width: 80%;
}

table {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
}

thead {
    border-bottom: 1px solid black;
    padding: 10px;
}

tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
}

th {
    width: 100px;
    text-align: center;
}

td {
    width: 100px;
    text-align: center;
    font-size: 12px;
    padding-top: 1rem;
}

.Admin-section-page-table {
    overflow-y: scroll;
    height: 70vh;
}