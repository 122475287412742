.scholarship-section {
  overflow-x: hidden;
  gap: 100px;
}

.scholarship-banner {
  flex-direction: column;
}

.scholarship-banner-main > img {
  transition: all 0.5s ease-in-out;
}
.rotate-180 {
  rotate: 180deg;
}

.-rotate-180 {
  rotate: -180deg;
}

.scholarship-banner-main > h2 {
  font-size: 6vw;
}

.scholarhip-banner-mid {
  width: 90%;

  flex-wrap: wrap;

  gap: 5rem;
}

.scholarhip-banner-mid > img {
  width: 25%;
}

.scholarhip-banner-mid-text {
  flex-direction: column;

  width: 30%;
}

.scholarship-supporters-section {
  width: 90%;
  display: flex;

  gap: 5rem;

  padding: 20px 10px;

  background-color: #2fceff7d;

  border-radius: 9px;
}

.scholarship-supporters-section > div > img {
  width: 80%;
}

.scholarship-easy-section {
  width: 90%;

  flex-direction: column;

  gap: 2rem;
}

.scholarship-easy-heading-section {
  flex-direction: column;

  gap: 1rem;
}
.scholarship-easy-heading-section > h2 {
  font-size: 3vw;
}

.scholarship-easy-cards-section {
  gap: 5rem;

  width: 100%;

  flex-wrap: wrap;
}

.scholarship-easy-cards {
  flex-direction: column;

  width: 300px;

  font-size: 1.2rem;

  gap: 1rem;
}

.scholarship-top-choice-section {
  flex-direction: column;

  width: 90%;

  text-align: left;
  align-items: flex-start;

  gap: 2rem;
}

.scholarship-top-choice-heading-section > h3 {
  font-size: 3vw;
}

.scholarship-top-choice-cards-section {
  gap: 1rem;

  flex-wrap: wrap;
}

.scholarship-top-choice-cards {
  flex-direction: column;

  text-align: left;
  align-items: flex-start;

  gap: 1rem;

  width: 400px;
}

.scholarship-top-choice-cards > img {
  width: 100%;

  border-radius: 20px;
}

.scholarship-welcome-section {
  width: 100%;
  gap: 2rem;

  flex-wrap: wrap;
}

.scholarship-welcome-section > img {
  width: 100px;
}

.scholarship-welcome-image-section {
  width: 25%;
}
.scholarship-welcome-image-section > img {
  width: 100%;
}

.scholarship-welcome-text-section {
  width: 50%;
  flex-direction: column;

  text-align: left;

  align-items: flex-start;

  gap: 1rem;
}

.scholarship-welcome-text-section > h3 {
  font-size: 3vw;
}

.scholarship-main-card {
  width: 70%;

  gap: 10rem;

  align-items: flex-start;

  background-color: #d6f1e7;

  padding: 5rem 3rem;

  border-radius: 15px;

  flex-wrap: wrap;
  row-gap: 2rem;
}

.scholarship-main-card-left {
  flex-direction: column;

  align-items: flex-start;
  text-align: left;

  gap: 1rem;

  width: 40%;
}

.scholarship-main-card-left > h4 {
  font-size: 2.2vw;

  font-weight: normal;
}

.scholarship-main-card-right {
  background-color: #e6f7f1;

  padding: 20px;

  border-radius: 15px;

  rotate: 15deg;
}

@media screen and (max-width: 1024px) {
  .scholarship-banner-main > h2 {
    font-size: 5vw;
  }

  .scholarhip-banner-mid {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .scholarhip-banner-mid > img {
    width: 50%;
  }

  .scholarhip-banner-mid-text {
    font-size: 3vw;
    width: 100%;
  }

  .scholarship-supporters-section {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    text-align: left;
  }
  .scholarship-supporters-section > div > img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .scholarhip-banner-mid > img {
    width: 100%;
  }

  .scholarship-banner-main > h2 {
    font-size: 6vw;
  }
  .scholarhip-banner-mid-text {
    font-size: 4vw;
    width: 100%;
  }
  .scholarship-welcome-text-section {
    align-items: flex-start !important;
  }

  .scholarship-welcome-text-section > h3 {
    font-size: 2rem;
  }

  .scholarship-main-card-left > h4 {
    font-size: 2rem;

    font-weight: normal;
  }
}
