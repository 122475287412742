.sub-appliction-form-section {
  margin: 20px 0px;
}
.application-form-text {
  padding: 15px;
  display: flex;
  align-self: flex-start;
  margin: 20px 0px;
  color: #4f4f4f;
}

.application-form-text > h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-form-img-content-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.application-form-img-box {
  width: 45%;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 30px;
}
.application-form-sub-img-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 40px;
}

.application-form-sub-img-box > img {
  width: 100%;
  border-radius: 10px;
}

.application-form-content-main-box {
  width: 43%;
}
.application-form-content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  text-align: left;
}
.application-form-sub-content-box-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.application-form-sub-content-box-heading > h2 {
  -webkit-text-stroke: 1px var(--secondary-highlight-color);
  color: transparent;
  font-size: 150px;
}
.application-form-sub-content-box-heading > h3 {
  font-size: 40px;
  color: var(--primary-text-para);
}
.application-form-sub-content-box-para > h4,
.application-form-sub-content-box-para > p {
  color: var(--primary-text-para);
}
.application-form-section {
  margin: 60px 0;
  width: 80%;
}
@media only screen and (max-width: 750px) {
  .application-form-img-content-box {
    flex-direction: column;
  }
  .application-form-img-box {
    display: none;
  }
  .application-form-content-main-box {
    width: 90%;
  }
  .application-form-sub-content-box-heading > h3 {
    font-size: 16px;
  }
  .application-form-sub-content-box-heading > h2 {
    font-size: 25px;
  }
  .application-form-cast-field {
    flex-wrap: wrap;
  }
}
