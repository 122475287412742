.program-page-learning-philosophy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  font-size: 19px;
}

.program-page-learning-philosophy-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  justify-content: space-between;
  width: 45%;
  padding-left: 2rem;
  height: 100%;
}

.box-border-right {
  border-left: 1px solid black;
}

.program-page-learning-philosophy-apply-button {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  margin: 40px 0;
  margin-bottom: 100px;
}
.apply-button {
  background-color: #155864;
  color: white;
  border-radius: 20px;
  padding: 10px 40px;
  border: none;
  box-shadow: 0px 2px 5px #155864;
}
.download-button {
  color: #155864;
  border-radius: 20px;
  padding: 10px 25px;
  display: flex;
  gap: 20px;
}

.program-page-program-details {
  display: flex;
  gap: 20px;
  margin: 40px 0px;
}
.program-page-program-details > h3 {
  display: flex;
  gap: 20px;
  font-family: Rubik;
  height: fit-content;
}

.highlighted-heading-1 {
  padding: 10px;
  background-color: var(--primary-buttons-color);

  border-radius: 8px;
  color: var(--primary-white);
  font-size: 24px;

  height: fit-content;
  width: fit-content;
}

.highlited-heading-2 {
  padding: 10px;
  background-color: var(--primary-buttons-color);

  border-radius: 8px;
  color: var(--primary-white);
  font-size: 24px;
}

.program-page-program-details > svg {
  margin-top: 15px;
}
.program-page-program-details-card {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* align-items: center;
  justify-content: center; */
  gap: 2rem;
  /* background-image: url("../../Assets/ProgramPage/bg\ img.png"); */
  background-size: contain;
  background-repeat: no-repeat;
}

.program-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sub-program-page-section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
}

.section-divider {
  width: 100%;
  height: 3px;

  border-radius: 8px;

  background-color: #15586438;
}

.section-divider-2 {
  width: 90%;
  height: 3px;

  border-radius: 8px;

  background-color: #15586438;
}

.sub-program-page-section > h3 {
  padding: 10px;
  background-color: var(--primary-buttons-color);

  border-radius: 8px;
  color: var(--primary-white);
  font-size: 24px;
}

.program-page-program-details-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 4rem;
}

.program-page-gain-comp {
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  /* width: 100%; */
}

.program-page-gain-comp-main {
  background-color: #f2fbfd;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  text-align: start;
  padding: 2rem;
  border-radius: 1rem;
}

.program-page-gain-comp-main-heading {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.program-page-gain-comp-main-heading-points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: start;
  gap: 1rem;
  width: 100%;
}

.program-page-gain-comp-main-heading-img {
  width: 80%;
}

.program-page-outcome-comp {
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
}

.program-page-outcome-comp-main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  text-align: start;
  padding: 2rem;
  width: 100%;
}

.program-page-outcome-comp-main-points {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.program-page-outcome-comp-main-img {
  width: 90%;
}

.program-page-eligibility {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program-page-eligibility-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.program-page-eligibility-heading {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.program-page-eligibility-main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
}

.program-page-eligibility-main-content-left {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* width: 80%; */
}
.program-page-eligibility-main-content-left-heading > h3 {
  /* border-right: 1px solid black; */
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: start;
}
.program-page-eligibility-main-content-left-point > p {
  /* padding-right: 3rem; */
  /* padding-left: 3rem; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: start;
}

/* .program-page-eligibility-main-content-right-img {
  width: 60%;
} */

@media screen and (max-width: 2000px) {
}

@media screen and (max-width: 1150px) {
  .program-page-gain-comp-main {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
  .program-page-outcome-comp-main {
    grid-template-columns: repeat(2, 1fr);
  }
  .program-page-eligibility-main-content {
    flex-direction: column;
  }
  .program-page-eligibility-main {
    background-image: none !important;
  }
  .program-page-eligibility-main-content {
    padding: 2rem;
  }
  .contactCompInProgramPages {
    height: fit-content !important;
  }
}

@media screen and (max-width: 650px) {
  .program-page-learning-philosophy-apply-button {
    flex-direction: column;
    width: 100%;
  }
  .program-page-program-details {
    flex-direction: column;
  }
  .program-page-program-details-card {
    grid-template-columns: repeat(2, 1fr);
  }
  .program-page-gain-comp-main {
    text-align: center;
  }
  .program-page-gain-comp-main-heading-points {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 2rem;
  }
  .program-page-gain-comp-main-heading-img-div {
    padding: 1rem;
  }
  .program-page-outcome-comp-main {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .program-page-outcome-comp {
    gap: 1rem;
  }
  .program-page-outcome-comp-main-img-div {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .program-page-eligibility-heading {
    flex-direction: column;
  }
  .program-page-eligibility-main-content {
    padding: 1rem;
  }
  .program-page-eligibility-main-content-left-heading > h3 {
    font-size: 16px;
  }
  .sub-program-page-section {
    gap: 2rem;
  }
  .program-page-section {
    width: 80%;
  }

  .program-page-learning-philosophy {
    flex-direction: column;
  }
  .program-page-learning-philosophy-box {
    font-size: 16px;
    width: 100%;
    padding: 0;
  }
  .box-border-right {
    border-left: none;
  }
}
