.programPostGraduate-page {
  overflow-x: hidden;
  padding-top: 6rem;
  /* padding-bottom: 6rem; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}
.programPostGraduate-page-banner-text {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
}
.programPostGraduate-page-banner {
  position: relative;
  /* padding-top: 6rem; */
  /* padding-bottom: 6rem; */
  width: 100%;
}
.programPostGraduate-page-banner > img {
  width: 80%;
}

.programPostGraduate-page-banner-breadcrumbs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  padding: 1rem;
}

.programPostGraduate-ProgrammeManagement {
  color: #080808;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
}
.programPostGraduate-ProgrammeManagement-main {
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 1rem;
  text-align: start;
  padding: 2rem;
}
.programPostGraduate-ProgrammeManagement-main-left {
  width: 60%;
  display: flex;
  flex-direction: column;

  gap: 2rem;
}
.programPostGraduate-ProgrammeManagement-main-right {
  width: 40%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.programPostGraduate-transformation-comp {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.programPostGraduate-SpecialCertificationsandTraining {
  display: flex;
  flex-direction: row;
  width: 80%;
  gap: 2rem;
  align-items: center;
}
.programPostGraduate-SpecialCertificationsandTraining-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.programPostGraduate-SpecialCertificationsandTraining-left-heading {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.programPostGraduate-SpecialCertificationsandTraining-left-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.programPostGraduate-SpecialCertificationsandTraining-right {
  width: 50%;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.programPostGraduate-belowSections {
  width: 90%;
}
.programPostGraduate-programOutcome {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
}
.programPostGraduate-programOutcome > h2 {
  display: flex;
  align-self: center;
}
.programPostGraduate-programOutcome-main {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  font-size: 18px;
  text-align: start;
}

@media screen and (max-width: 2000px) {
}

@media screen and (max-width: 1150px) {
  .programPostGraduate-page {
    padding-top: 8rem;
    width: 95%;
  }
  .programPostGraduate-ProgrammeManagement-main {
    flex-direction: column;
    width: 100%;
  }
  .programPostGraduate-ProgrammeManagement-main-left {
    width: 100%;
  }
  .programPostGraduate-ProgrammeManagement-main-right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .programPostGraduate-page {
    padding-top: 8rem;
    width: 95% !important;
  }
  .programPostGraduate-page-banner {
    width: 100%;
    height: 40vh;
  }
  .programPostGraduate-page-banner > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .programPostGraduate-page-banner-text > h2 {
    font-size: 22px;
  }
  .programPostGraduate-page-banner-breadcrumbs {
    font-size: 14px;
  }
  .programPostGraduate-SpecialCertificationsandTraining {
    flex-direction: column;
  }
  .programPostGraduate-SpecialCertificationsandTraining {
    width: 90%;
  }
  .programPostGraduate-SpecialCertificationsandTraining-left-heading {
    flex-direction: column;
  }
  .programPostGraduate-SpecialCertificationsandTraining-left {
    width: 100%;
  }
  .programPostGraduate-SpecialCertificationsandTraining-right {
    width: 100%;
  }
  .programPostGraduate-programOutcome-main {
    flex-direction: column;
  }
  .programPostGraduate-programOutcome-main > img {
    width: 100%;
  }
  .program-page-eligibility-main-content-right-img {
    width: 80%;
  }
  .programPostGraduate-page {
    width: 80%;
  }
}
