.blogs-page {
}

.blogs-page-section {
  margin-bottom: 5rem;
}

.blogs-page-section > a {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.blogs-page-main-blog {
  width: 80%;
  height: 50vh;

  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;

  justify-content: flex-start;

  position: relative;

  cursor: pointer;

  border-radius: 15px;

  transition: transform 0.3s ease-in-out;
}

.blogs-page-main-blog > div {
  color: var(--primary-white);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  align-self: flex-end;
  justify-self: flex-start;

  padding-left: 1rem;
  padding-bottom: 1rem;
}

.blogs-page-main-blog > h4 {
  color: var(--primary-white);

  width: 100%;

  font-size: 3rem;

  position: absolute;

  top: 50%;
  left: 0%;
  display: none;

  transition: all 0.3s ease-in-out;
}

.blogs-page-main-blog:hover {
  transform: scale(1.1);

  transition: transform 0.3s ease-in-out;
}

.blogs-page-main-blog:hover > h4 {
  display: inline;

  transition: all 0.3s ease-in-out;
}

.blogs-page-blogs-showcase-section {
  margin-top: 2rem;
  flex-wrap: wrap;

  justify-content: center;
  align-items: flex-start;

  gap: 2.5rem;
}

.blogs-page-blogs-card {
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  width: 320px;
  height: 400px;

  border-radius: 8px;

  padding: 1rem;

  gap: 0.8rem;

  text-align: left;

  cursor: pointer;

  transition: transform 0.3s ease-in-out;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.blogs-page-blogs-card:hover {
  transform: scale(1.1);

  transition: transform 0.3s ease-in-out;
}

.blogs-page-blogs-card > img {
  width: 100%;

  border-radius: 8px;
}

.blogs-page-blogs-card > div > h3 {
  font-size: 22px;
}

.blog-page-error {
  width: 100%;
  height: 50vh;

  margin-top: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .blogs-page-section {
    margin-top: 100px;
  }

  .blogs-page-section > a {
  }

  .blogs-page-main-blog {
    height: 50vh !important;
  }
  .blogs-page-main-blog > div {
    align-self: center;
    text-align: center;

    justify-content: center;
    align-items: center;

    font-size: 2rem;
  }

  .blogs-page-main-blog > h4 {
    display: flex;
    position: relative;

    font-size: 2rem;

    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
