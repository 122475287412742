@media screen and (max-width: 1024px) {
  .contact-card {
    flex-direction: column !important;
    padding: 0 !important;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    width: 100% !important;

    gap: 50px !important;

    background: #fff !important;
  }

  .contact-card-left {
    width: 90% !important;
  }

  .contact-card-left > span {
    width: 70% !important;
  }

  .contact-card-contact-form-section {
    width: 100% !important;
  }

  .contact-card-right {
    width: 100% !important;
  }

  .contact-card-right-map {
    height: 30vh !important;
    width: 90% !important;
  }

  .contact-card-details-container {
    flex-direction: row !important;

    width: 90% !important;

    gap: 10px !important;

    justify-content: flex-start !important;
    padding-left: 10px !important;
  }
  .contact-card-details-container > svg {
    width: 30px !important;
    height: 30px !important;
  }

  .contact-card-details-head {
    gap: 0px !important;
    font-size: 1rem !important;
    width: 60% !important;
    align-items: flex-start !important;
  }
}

.contactCompInProgramPages {
  height: auto !important;
}

.contact-card {
  justify-content: space-around;

  width: 100%;
  height: 100%;

  font-size: 1rem;

  border-radius: 40px;

  padding: 50px 10px 20px 10px;

  box-shadow: 10px 20px 50px #bbced1;

  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 80%,
    var(--secondary-color-1) 20%
  );
}

.contact-card-left {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 40%;
  height: 100%;

  gap: 20px;

  text-align: left;
}

.contact-card-left > h2 {
  font-size: 2rem;
}

.contact-card-contact-form-section {
  width: 100%;
  height: 40vh;

  margin-top: 5%;
}

.contact-card-left-details {
  flex-direction: column;
  align-items: flex-start;

  margin-top: 30px;

  gap: 40px;

  font-size: 0.8rem;
}

.contact-card-details-container {
  width: 50%;

  gap: 10px;
}

.contact-card-details-container > svg {
  width: 40px;
  height: 40px;
}

.contact-card-details-head {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  gap: 5px;

  text-align: left;
}

.contact-card-details-head > span {
  width: 100%;
}

.contact-card-details-head a {
  text-decoration: none;
  color: var(--primary-color-1);
}

.contact-card-right {
  width: 40%;

  height: 100%;

  flex-direction: column;

  background-color: transparent;

  align-items: center;
  justify-content: center;

  gap: 20px;
}

.contact-card-right-map {
  width: 100%;
  height: 50%;
}
