.adminlogin {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.adminlogin > img {
    width: 300px;
}

.adminlogin-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.adminlogin-form-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    color: #6E798C;
}

.adminlogin-form-inputs > input {
    border: 1px solid #6E798C;
    padding: 10px;
    width: 300px;
    border-radius: 8px;
}

.adminlogin-form > button {
    padding: 10px;
    font-size: 20px;
    border-radius: 8px;
    border: none;
    background: #083A44;
    color: white;
}