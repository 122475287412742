.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: capitalize;

  color: var(--primary-white);

  font-size: large;

  font-weight: bold;

  background-color: var(--primary-color-1);

  border: none;
  border-radius: 1rem;

  padding: 1rem 2rem;
}
