@media screen and (max-width: 1024px) {
  .contact-form-main {
    width: 100% !important;
    min-height: 50vh !important;
    padding: 0 !important;

    font-size: 1rem !important;
  }

  .contact-form-main > form > div > input,
  textarea {
    width: 100%;
    min-height: 20px;

    font-size: 1.2rem !important;

    font-family: Rubik;

    background-color: transparent;

    border-bottom: 1px solid #737b7d;
  }

  .contact-form-main > form {
    gap: 30px !important;
  }

  .contact-form-main > form > button {
    font-size: 1.5rem !important;
  }
}

.contact-form-main {
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.contact-form-main > form {
  flex-direction: column;

  gap: 10px;

  width: 100%;
  height: 100%;
}

.contact-form-main > form > div {
  width: 100%;
  height: 50%;
}

.contact-form-main > form > div > input,
textarea {
  width: 100%;
  min-height: 30px;

  font-size: 0.8rem;

  font-family: Rubik;

  background-color: transparent;

  border-bottom: 1px solid #737b7d;
}

.contact-form-main > form > div > textarea {
  resize: none;
}

.contact-form-main > form > div > span {
  display: flex;
  justify-self: flex-start;
  color: red;
  text-align: left;
}

.contact-form-main-program-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
}
.contact-form-main-program-input > select {
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  border-bottom: 1px solid #737b7d;
  color: #737b7d;
}
.contact-form-main-program-input-option {
  padding: 10px;
}

.contact-form-main > form > button {
  width: 100%;
  padding: 10px 0px 10px 0px;

  border: none;

  border-radius: 8px;

  color: var(--primary-white);

  background-color: var(--secondary-color-1);
  box-shadow: 0px 10px 20px #9bbbc1;
}

/* Loader Animation */
.loading-spinner {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #3498db; /* Blue border for animation */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animation function, 1s duration, linear timing, infinite loop */
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Success Message Animation */
.submission-success {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2ecc71; /* Green color for success message */
  font-size: 18px;
  font-weight: bold;
}

.submission-success span {
  margin-right: 10px;
  animation: scaleUp 0.5s ease; /* Animation function, 0.5s duration, ease timing */
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
