.programPage-PGDM {
  padding-top: 6rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.programPage-PGDM-hero {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.programPage-PGDM-hero-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: start;
  width: 50%;
}

.programPage-PGDM-hero-left > p {
  background-color: var(--primary-white);
  padding: 5px;
  border-radius: 8px;
}
.programPage-PGDM-hero-left-button {
  background: #155864;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
  padding: 1rem;
  border-radius: 9999px;
}
.programPage-PGDM-hero-left-button > p {
  color: white;
}
/* .programPage-PGDM-hero-right {

} */
.programPage-PGDM-hero-right-up {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.programPage-PGDM-hero-right-up > img {
  width: 50%;
}
.programPage-PGDM-hero-right-down {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.programPage-PGDM-hero-right-down > img {
  width: 50%;
}
.programPage-PGDM-keyfeatures-section-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.programPage-PGDM-keyfeatures-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid #a4a4a4;
  border-radius: 10px;
  box-shadow: 0px 8px 10px 0px rgba(153, 153, 153, 0.1);
  gap: 2rem;
}
.programPage-PGDM-keyfeatures-section-heading > img {
  width: 50px;
  height: 8px;
}
.programPage-PGDM-keyfeatures-section-main {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.programPage-PGDM-keyfeatures-section-main-left {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 70%;
}
.programPage-PGDM-keyfeatures-section-main-right {
  width: 30%;
  border: 1px solid #269eff;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.programPage-PGDM-keyfeatures-section-main-left-point {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.programPage-PGDM-keyfeatures-section-main-left-point > .point-check {
  font-size: 20px;
}
.programPage-PGDM-keyfeatures-section-main-left-point > p {
  font-size: 12px;
}
.programPage-PGDM-keyfeatures-section-main-right-button {
  background: linear-gradient(73deg, #0052ff 6.93%, #2fceff 107.53%);

  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
  padding: 1rem;
  border-radius: 10px;
}
.programPage-PGDM-digitalMarketingSyllabus {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.programPage-PGDM-digitalMarketingSyllabus-head {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.programPage-PGDM-digitalMarketingSyllabus-accordions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.programPage-PGDM-digitalMarketingMaster {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.programPage-PGDM-digitalMarketingMaster-head {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: start;
}
.programPage-PGDM-digitalMarketingMaster-toolLogos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  align-items: center;
}
.programPage-PGDM-digitalMarketingMaster-toolLogos > img {
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.programPage-PGDM-digitalMarketingNextGen {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
}
.programPage-PGDM-digitalMarketingNextGen-left {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 50%;
  gap: 2rem;
}
.programPage-PGDM-digitalMarketingNextGen-right {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.programPage-PGDM-digitalMarketingNextGen-right > img {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  width: 150px;
  height: 50px;
  padding: 10px;
}

@media screen and (max-width: 2000px) {
}

@media screen and (max-width: 1150px) {
  .programPage-PGDM-keyfeatures-section-main {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .programPage-PGDM-keyfeatures-section-main-left {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .programPage-PGDM-keyfeatures-section-main-right {
    width: 80%;
  }
  .programPage-PGDM-digitalMarketingMaster-toolLogos {
    grid-template-columns: repeat(3, 1fr);
  }
  .programPage-PGDM-digitalMarketingNextGen {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .programPage-PGDM-digitalMarketingNextGen-left {
    width: 100%;
  }
  .programPage-PGDM-digitalMarketingNextGen-right {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .programPage-PGDM-hero-left > p {
    padding: 0px !important;
  }
  .programPage-PGDM-hero {
    flex-direction: column;
    gap: 2rem;
  }
  .programPage-PGDM-hero-left {
    width: 100%;
  }
  .programPage-PGDM-digitalMarketingSyllabus-accordions {
    grid-template-columns: repeat(1, 1fr);
  }
  .programPage-PGDM-digitalMarketingMaster-toolLogos {
    grid-template-columns: repeat(2, 1fr);
  }
  .programPage-PGDM {
    width: 80%;
  }
}
