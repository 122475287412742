@media screen and (max-width: 768px) {
  .contactUs-detail {
    flex-direction: column !important;
    align-items: center;
  }
  .contact-detail-form {
    width: 90% !important;
  }

  .contact-detail-form-main-box {
    flex-direction: column !important;
    width: 90% !important;
  }

  .contact-detail-form-box {
    width: 90% !important;
    font-size: 13px;
  }

  .contact-detail-form-icon-data {
    width: 90% !important;
    /* margin-top: 40px; */
  }

  .contact-detail-form-icon-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    gap: 25px;
    width: 90% !important;
  }

  .contact-map {
    width: 90% !important;
    height: 30vh !important;
  }
}

.contactUs-detail {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px 0;
}
.contact-detail-form {
  width: 40%;
}
.contact-detail-form-icon-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  width: 40%;
}
.contact-detail-form-main-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  text-align: start;
  gap: 10px;
  width: 90%;
  font-size: 12px;
}
.contact-detail-form-box {
  width: 50%;
  min-height: 30vh;
  border: 1px dashed gray;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-detail-form-box > span > a {
  color: #4f4f4f;
  text-decoration: none;
}

.contact-detail-form-box > span > a:hover {
  color: var(--primary-buttons-color);

  text-decoration: underline;
}

.contact-detail-form-box > img {
  width: 35%;
}
.contact-social-icon {
  display: flex;
  gap: 15px;
  align-items: center;
}
.contact-map {
  width: 100%;
  height: 70vh;
}

.contact-map > iframe {
  border-radius: 15px;
  border: none;
}
